import * as React from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { useState, useEffect } from "react";
import throttle from "lodash.throttle";
import { cn } from "@/utils/cn";

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = "vertical", ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      "flex touch-none select-none transition-colors",
      orientation === "vertical" && "h-full w-2 border-l border-l-transparent p-[1px]",
      orientation === "horizontal" && "h-2 flex-col border-t border-t-transparent p-[1px]",
      className,
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className={cn("flex-1 rounded-full bg-slate-400", orientation === "vertical" && "flex-1")} />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;
ScrollBar.propTypes = ScrollAreaPrimitive.ScrollAreaScrollbar.propTypes;

interface CustomScrollAreaProps {
  shadowOptions?: {
    size?: number;
    x?: boolean;
    y?: boolean;
  };
  scrollAreaClassName?: string;
}

type ScrollAreaProps = React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & CustomScrollAreaProps;

const ScrollArea = React.forwardRef<React.ElementRef<typeof ScrollAreaPrimitive.Root>, ScrollAreaProps>(
  ({ className, children, shadowOptions, scrollAreaClassName, ...props }, ref) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isScrollable, setIsScrollable] = useState({ x: false, y: false });
    const viewportRef = React.useRef<HTMLDivElement>(null);
    const childrenRef = React.useRef<HTMLDivElement>(null);

    const handleScroll = throttle((e) => {
      if (shadowOptions?.y) {
        setIsScrolled(e.target.scrollTop > 0);
      } else if (shadowOptions?.x) {
        setIsScrolled(e.target.scrollLeft > 0);
      }
    }, 100);

    useEffect(() => {
      if (viewportRef?.current && childrenRef?.current) {
        setIsScrollable({
          x: viewportRef.current.clientWidth < childrenRef.current.clientWidth,
          y: viewportRef.current.clientHeight < childrenRef.current.clientHeight,
        });
      }
    }, [children]);

    return (
      <ScrollAreaPrimitive.Root ref={ref} className={cn("relative overflow-hidden", className)} {...props}>
        <ScrollAreaPrimitive.Viewport
          ref={viewportRef}
          className={cn(
            "h-full w-full rounded-[inherit]",
            {
              "data-[bottom-scroll=true]:[mask-image:linear-gradient(180deg,#000_calc(100%_-_var(--scroll-shadow-size)),transparent)] data-[top-bottom-scroll=true]:[mask-image:linear-gradient(#000,#000,transparent_0,#000_var(--scroll-shadow-size),#000_calc(100%_-_var(--scroll-shadow-size)),transparent)] data-[top-scroll=true]:[mask-image:linear-gradient(0deg,#000_calc(100%_-_var(--scroll-shadow-size)),transparent)]":
                shadowOptions?.y,
              "data-[left-right-scroll=true]:[mask-image:linear-gradient(#000,#000,transparent_0,#000_var(--scroll-shadow-size),#000_calc(100%_-_var(--scroll-shadow-size)),transparent)] data-[left-scroll=true]:[mask-image:linear-gradient(270deg,#000_calc(100%_-_var(--scroll-shadow-size)),transparent)] data-[right-scroll=true]:[mask-image:linear-gradient(90deg,#000_calc(100%_-_var(--scroll-shadow-size)),transparent)]":
                shadowOptions?.x,
            },
            scrollAreaClassName,
          )}
          onScrollCapture={shadowOptions?.x || shadowOptions?.y ? handleScroll : undefined}
          style={{ "--scroll-shadow-size": `${shadowOptions?.size || 40}px` } as React.CSSProperties}
          data-top-scroll={shadowOptions?.y && isScrolled && isScrollable.y}
          data-bottom-scroll={shadowOptions?.y && !isScrolled && isScrollable.y}
          data-left-scroll={shadowOptions?.x && isScrolled && isScrollable.x}
          data-right-scroll={shadowOptions?.x && !isScrolled && isScrollable.x}
        >
          <div ref={childrenRef}>{children}</div>
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    );
  },
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

export { ScrollArea, ScrollBar };
