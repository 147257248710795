import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import { advancedBoxVariants } from "@/components/boxes/AdvancedBox";
import { COOKIE_LOCALE_NAME } from "@/constants/i18n";
import { useGetCurrentLanguageQuery } from "@/data-access/query/__generated__/getCurrentLanguage";
import { useGetLanguagesQuery } from "@/data-access/query/__generated__/getLanguages";
import { Label } from "@radix-ui/react-label";
import { setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useState } from "react";
import CountryFlag from "./CountryFlag";
import LoadingIcon from "./Icon/icons/loading";
import { ScrollArea } from "./ScrollArea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";

const LANGUAGE_COOKIE_LENGTH = 60 * 60 * 24 * 30 * 12; // 1 year

interface LanguageOptionProps {
  id: string;
  icon?: string | null;
  label?: string | null;
}

const LanguageOption = ({ id, icon, label }: LanguageOptionProps) => (
  <Label htmlFor={id} className={advancedBoxVariants({ className: "flex cursor-pointer items-center justify-between" })}>
    <RadioGroupItem
      value={id}
      id={id}
      className="focus-visible:ring-primary-300 peer focus-visible:ring-2 focus-visible:ring-offset-2"
      tabIndex={0}
    />
    <div className="flex items-center gap-4">
      <CountryFlag countryCode={icon ?? id} className="h-8 w-8 bg-transparent" />

      <p className="min-w-[10ch]">{label}</p>
    </div>
  </Label>
);

interface LanguageSwitchProps {
  type?: "select" | "radio-button";
}

const LanguageSwitch = ({ type = "select" }: LanguageSwitchProps) => {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const [isChangeLoading, setIsChangeLoading] = useState(false);

  const { data: languagesData, loading: languagesLoading } = useGetLanguagesQuery();
  const { data: currentLanguageData, loading: currentLanguageLoading } = useGetCurrentLanguageQuery();

  const isLoading = languagesLoading || currentLanguageLoading || isChangeLoading;

  if (!languagesData?.languages || !currentLanguageData?.language) {
    return null;
  }

  const { languages: languagesRaw } = languagesData;
  const { language: currentLanguage } = currentLanguageData;

  // TODO: remove when FE sends only usage langs

  const onLanguageChange = async (selectedLanguage: string) => {
    setIsChangeLoading(true);
    setCookie(COOKIE_LOCALE_NAME, selectedLanguage, {
      maxAge: LANGUAGE_COOKIE_LENGTH,
    });

    await router.push({ pathname, query }, asPath, { locale: selectedLanguage });

    router.reload();
  };

  if (type === "radio-button") {
    return (
      <RadioGroup defaultValue={currentLanguageData.language.id} className="w-full gap-y-4" onValueChange={onLanguageChange} disabled={isLoading}>
        {languagesData?.languages.map((lang) => <LanguageOption key={lang.id} id={lang.id} icon={lang.icon} label={lang.localizedName} />)}
      </RadioGroup>
    );
  }

  return (
    <Select value={currentLanguage.id} onValueChange={onLanguageChange} disabled={isLoading}>
      <SelectTrigger className="h-8 w-fit shrink-0 gap-1 border px-2 focus:outline-none focus:ring-0 focus:ring-offset-0">
        <SelectValue className="py-1">
          {!isLoading ? (
            <div className="flex h-5 w-5 items-center justify-center overflow-hidden rounded-full bg-black-100">
              <CountryFlag countryCode={currentLanguage.icon ?? currentLanguage.id} className="h-[30px] w-[30px] flex-shrink-0 flex-grow text-xs" />
            </div>
          ) : (
            <LoadingIcon className="h-5 w-5 animate-spin opacity-25" />
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent position="popper" updatePositionStrategy="always">
        <ScrollArea>
          {languagesRaw.map((lang) => (
            <SelectItem key={lang.id} value={lang.id} className="cursor-pointer">
              <div className="flex items-center gap-2">
                <CountryFlag countryCode={lang.icon ?? lang.id} />
                {lang.localizedName}
              </div>
            </SelectItem>
          ))}
        </ScrollArea>
      </SelectContent>
    </Select>
  );
};

export default LanguageSwitch;
