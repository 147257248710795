import useDeviceLayoutStore from "@/lib/store/useDeviceLayoutStore";
import OreaLogoWithText from "../Icon/icons/orea-logo-with-text";
import LanguageSwitch from "../LanguageSwitch";
import { Shell } from "../Shell";

const SimpleNav = () => {
  const hideLanguageSwitcher = useDeviceLayoutStore((state) => state.hideLanguageSwitcher);

  return (
    <Shell as="nav" className="flex w-full items-center justify-between">
      <div>
        <OreaLogoWithText className="h-8" />
      </div>

      {!hideLanguageSwitcher && (
        <nav className="flex items-center gap-7">
          <LanguageSwitch />
        </nav>
      )}
    </Shell>
  );
};

export default SimpleNav;
