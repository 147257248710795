import { sanitize } from "isomorphic-dompurify";
import { cn } from "../utils/cn";

interface Props {
  className?: string;
  htmlContent: string | null | undefined;
}

const RichTextViewer = ({ className, htmlContent }: Props) =>
  htmlContent && (
    <div
      className={cn("wysiwyg-h2:h2 wysiwyg max-w-none wysiwyg-h2:mb-5 wysiwyg-p:mb-5 wysiwyg-p:text-primary-dark wysiwyg-a:text-share", className)}
      dangerouslySetInnerHTML={{ __html: sanitize(htmlContent, { ADD_ATTR: ["target"] }) }}
    />
  );

export default RichTextViewer;
