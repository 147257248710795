import Icon, { IconProps } from "../Icon";

const LoadingIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
  </svg>
);

const LoadingIcon = (props: IconProps) => (
  <Icon {...props}>
    <LoadingIconSvg />
  </Icon>
);

export default LoadingIcon;
