import { useSession } from "@/providers/SessionProvider";
import { HotelIdentifiers } from "@/types";
import { ReactNode } from "react";
import { Toaster } from "../Toaster";
import SiteHeader from "./SiteHeader";

export type NavbarMode = "simple" | "full";

interface Props {
  children?: ReactNode;
  hotel?: HotelIdentifiers;
  navbarMode?: NavbarMode;
}
const LayoutBase = ({ children, hotel, navbarMode = "full" }: Props) => {
  const { isLoggedIn } = useSession();

  return (
    <div className="flex min-h-screen flex-col">
      <SiteHeader key={isLoggedIn ? "logged-in" : "logged-out"} user={isLoggedIn} hotel={hotel} navbarMode={navbarMode} />

      <div className="isolate flex flex-grow flex-col">{children}</div>

      <Toaster />
    </div>
  );
};

export default LayoutBase;
