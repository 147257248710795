import { cva, VariantProps } from "class-variance-authority";
import { ButtonHTMLAttributes, cloneElement, forwardRef, ReactElement, ReactNode } from "react";
import { cn } from "../utils/cn";
import LoadingIcon from "./Icon/icons/loading";

const variants = cva(
  "relative inline-flex items-center justify-center rounded-4xl text-base transition-colors disabled:pointer-events-none disabled:opacity-60 focus:outline-none font-semibold",
  {
    variants: {
      variant: {
        default:
          "bg-button-primary text-button-primary-text focus:ring-4 focus:ring-button-primary-focus-ring focus:bg-button-primary-focus hover:bg-button-primary-hover active:bg-button-primary-active active:ring-2 active:ring-button-primary-active-ring",
        secondary:
          "bg-button-secondary text-button-secondary-text border border-primary-dark focus:ring-1 focus:ring-button-secondary-focus-ring focus:bg-button-secondary-focus hover:bg-button-secondary-hover active:border-button-secondary-active-border",
        tertiary: "text-button-tertiary-text focus:text-button-tertiary-focus hover:text-button-tertiary-hover underline underline-offset-2",
        link: "text-share focus:underline hover:underline",
        basic: "static block text-left rounded-3xl",
        dark: "bg-black text-white hover:bg-black/65",
        input:
          "border-primary/20 focus:border-input-focus flex w-full rounded-xl border bg-white text-base text-primary-dark transition-colors placeholder:text-stone-400 focus:outline-none focus:ring-0 disabled:cursor-not-allowed",
      },
      size: {
        sm: "py-2.5 px-4",
        default: "py-3 px-6",
        md: "py-3.5 px-6",
        lg: "py-3 px-8",
        icon: "p-1",
        input: "px-4 py-3",
        reset: "p-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof variants> {
  isLoading?: boolean;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  hasShadow?: boolean;
  iconWrapperClass?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, iconWrapperClass, variant, isLoading, iconPosition = "left", icon, size, hasShadow, ...props }, ref) => {
    const renderIcon = (icon: ReactNode) =>
      cloneElement(icon as ReactElement, {
        className: cn("h-5 w-5", (icon as ReactElement).props.className),
      });

    return (
      <button
        className={cn(variants({ variant, size, className }), {
          "shadow-lg": hasShadow,
        })}
        ref={ref}
        disabled={isLoading}
        {...props}
      >
        {isLoading && (
          <span className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            <LoadingIcon className="h-5 w-5 animate-spin" />
          </span>
        )}

        <span
          className={cn(
            "inline-flex w-full items-center justify-center overflow-hidden",
            { "opacity-40 blur-[0.75px]": isLoading },
            iconWrapperClass,
          )}
        >
          {icon && iconPosition === "left" && <span className={cn({ "mr-2": children })}>{renderIcon(icon)}</span>}
          {children}
          {icon && iconPosition === "right" && <span className="ml-2">{renderIcon(icon)}</span>}
        </span>
      </button>
    );
  },
);
Button.displayName = "Button";

export default Button;
export { variants as buttonVariants };
