import { mainNav } from "@/config/site";
import { I18N_NAMESPACES } from "@/constants/i18n";
import { ROUTES } from "@/constants/routes";
import { HotelIdentifiers } from "@/types";
import { cn } from "@/utils/cn";
import { getHotelLocalizedLink } from "@/utils/localeUtils";
import { LogInIcon } from "lucide-react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { isServer } from "@/constants/common";
import OreaLogoWithText from "../Icon/icons/orea-logo-with-text";
import LanguageSwitch from "../LanguageSwitch";
import Link from "../Link";
import { Shell } from "../Shell";
import { AuthDropdown } from "./AuthDropdown";
import { isActive } from "./MobileNav";

interface Props {
  user?: boolean;
  hotel?: HotelIdentifiers;
}

const MainNav = ({ user, hotel }: Props) => {
  const { t } = useTranslation(I18N_NAMESPACES.COMMON);
  const router = useRouter();
  const items = user ? mainNav.filter((item) => item.labelKey !== "profile") : mainNav.filter((item) => !item.isPrivate);

  const referrer = !isServer ? document.referrer : "";
  const isFromOrea = referrer.includes("orea.cz");
  const externalLink = getHotelLocalizedLink(router.locale, hotel?.externalWebsiteUrl);
  const logoLink = router.pathname === ROUTES.SIGN_IN && isFromOrea ? referrer : externalLink;

  return (
    <Shell as="header" className="hidden w-full items-center justify-between md:flex">
      <Link href={logoLink} className="block w-fit focus-within:outline-none focus-within:ring-2 focus-within:ring-yellow-light-beige">
        <OreaLogoWithText className="h-8" />
      </Link>
      <nav className="flex items-center gap-7">
        {user ? (
          <ul className="flex gap-4">
            {items.map((item) => {
              const isFaq = item.href === ROUTES.FAQ;
              let link = item.href;

              if (isFaq && hotel?.id !== undefined) {
                link = `${ROUTES.FAQ}/${hotel.id}`;
              }

              return (
                <Link
                  key={item.labelKey}
                  href={link}
                  className={cn(
                    "my-auto rounded-md p-2 font-semibold focus-within:bg-yellow-light-beige/75 focus-within:outline-none hover:bg-yellow-light-beige/75",
                    {
                      "bg-yellow-light-beige": isActive(link, router.asPath),
                    },
                  )}
                >
                  {t(`nav.${item.labelKey}`)}
                </Link>
              );
            })}
            {user && <AuthDropdown user={user} />}
          </ul>
        ) : (
          <>
            <Link
              href={`${ROUTES.FAQ}${hotel?.id ? `/${hotel.id}` : ""}`}
              className={cn(
                "my-auto rounded-md p-2 font-semibold focus-within:bg-yellow-light-beige/75 focus-within:outline-none hover:bg-yellow-light-beige/75",
                {
                  "bg-yellow-light-beige": isActive(ROUTES.FAQ, router.asPath),
                },
              )}
            >
              {t("nav.faq")}
            </Link>
            <Link
              href={ROUTES.SIGN_IN}
              className={cn(
                "my-auto flex items-center gap-2 rounded-md p-2 font-semibold focus-within:bg-yellow-light-beige/75 focus-within:outline-none hover:bg-yellow-light-beige/75",
                {
                  "bg-yellow-light-beige": isActive(ROUTES.SIGN_IN, router.asPath),
                },
              )}
            >
              <LogInIcon className="size-5" />
              <span className="capsize-text">{t("nav.login")}</span>
            </Link>
          </>
        )}
        <LanguageSwitch />
      </nav>
    </Shell>
  );
};

export default MainNav;
