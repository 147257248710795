import { Avatar, AvatarFallback } from "@/components/Avatar";
import { buttonVariants, type ButtonProps } from "@/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/DropdownMenu";
import { Icons } from "@/components/icons";
import { ROUTES } from "@/constants/routes";
import useSignOut from "@/hooks/useSignOut";
import { useSession } from "@/providers/SessionProvider";
import { cn } from "@/utils/cn";
import { LogOut, User } from "lucide-react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import ResponsiveDrawerModal from "../ResponsiveDrawerModal";
import RichTextViewer from "../RichTextViewer";
import OreaSettingsIcon from "../Icon/orea-custom/orea-settings";

interface AuthDropdownProps extends React.ComponentPropsWithRef<typeof DropdownMenuTrigger>, ButtonProps {
  user: boolean;
}

export function AuthDropdown({ user, className, ...props }: AuthDropdownProps) {
  const { t } = useTranslation();
  const { user: userInfo } = useSession();
  const handleSignOut = useSignOut();

  const initials = `${userInfo?.firstName?.charAt(0).toUpperCase() ?? ""}${userInfo?.lastName?.charAt(0).toUpperCase() ?? ""}`;
  const showUserIcon = !userInfo?.firstName && !userInfo?.lastName;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className={cn(buttonVariants({ variant: "basic", size: "reset" }), "group")} {...props}>
          <Avatar className="size-7 transition-shadow text-sm group-hover:shadow-lg">
            <AvatarFallback> {showUserIcon ? <User className="h-full w-full p-1 text-white" /> : initials}</AvatarFallback>
          </Avatar>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-bold"> {t("accountMenu.myProfileLabel")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <Link href={ROUTES.ACCOUNT_PROFILE}>
              <Icons.profile className="mr-2 size-4" aria-hidden="true" />
              <span className="capsize-text">{t("accountMenu.profile")}</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link href={ROUTES.ACCOUNT_SETTINGS}>
              <OreaSettingsIcon className="mr-2 size-4" aria-hidden="true" />
              <span className="capsize-text">{t("accountMenu.settings")}</span>
            </Link>
          </DropdownMenuItem>
          <ResponsiveDrawerModal
            trigger={
              <button className="flex w-full cursor-pointer select-none items-center rounded-sm px-2 py-1.5 outline-none transition-colors text-sm data-[disabled]:pointer-events-none data-[disabled]:opacity-50 hover:bg-slate-100 focus:bg-slate-100 focus:text-slate-900 dark:focus:bg-slate-800 dark:focus:text-slate-50">
                <Icons.hotel className="mr-2 size-4" aria-hidden="true" />
                <span className="capsize-text">{t("accountMenu.about")}</span>
              </button>
            }
          >
            <RichTextViewer className="w-full" htmlContent={t("aboutUs_html")} />
          </ResponsiveDrawerModal>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <button className="w-full" onClick={handleSignOut}>
            <LogOut className="mr-2 size-4" aria-hidden="true" />
            <span className="capsize-text">{t("signOut")}</span>
          </button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
