import { createLucideIcon } from "lucide-react";

const OreaArrowBlueIcon = createLucideIcon("orea-arrow-blue", [
  [
    "path",
    {
      key: "path",
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M14.176 4.172 22 12m-7.824 7.824L22 12M2 12h20",
      vectorEffect: "non-scaling-stroke",
    },
  ],
]);

export default OreaArrowBlueIcon;
