import * as React from "react";
import { Drawer as DrawerPrimitive } from "vaul";

import { cn } from "@/utils/cn";
import { ChevronLeft, XIcon } from "lucide-react";
import Button from "./Button";

const Drawer = ({ shouldScaleBackground = true, ...props }: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
);

Drawer.displayName = "Drawer";

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => <DrawerPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-black/80", className)} {...props} />);
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

interface DrawerContentProps extends React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> {
  className?: string;
  onBackClick?: () => void;
}

const DrawerContent = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Content>, DrawerContentProps>(
  ({ className, children, onBackClick, ...props }, ref) => (
    <DrawerPortal>
      <DrawerOverlay className="bg-black/40 backdrop-blur-sm" />
      <DrawerPrimitive.Content
        ref={ref}
        className={cn(
          "fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-3xl border border-slate-200 bg-white pt-3 focus-visible:outline-none focus-visible:ring-2 dark:border-slate-800 dark:bg-slate-950",
          className,
        )}
        {...props}
      >
        <div className="relative grid grid-cols-3 rounded-t-xl bg-white px-4 pb-2">
          <div className="flex items-center">
            {onBackClick && (
              <button onClick={onBackClick} className="flex items-center">
                <ChevronLeft className="h-6 w-6 text-gold" />
                <span className="h5 text-lg font-bold">Zpět</span>
              </button>
            )}
          </div>
          <div className="cursor-pointer p-3">
            <div className="mx-auto mb-4 h-1.5 w-[5rem] flex-shrink-0 cursor-pointer rounded-full bg-black-100" />
          </div>
          <div className="flex justify-end">
            <DrawerClose asChild>
              <Button size="icon" className="inline-block self-end text-black" variant="link" icon={<XIcon className="h-6 w-6 stroke-1" />} />
            </DrawerClose>
          </div>
        </div>
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  ),
);
DrawerContent.displayName = "DrawerContent";

const DrawerHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)} {...props} />
);
DrawerHeader.displayName = "DrawerHeader";

const DrawerFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("mt-auto flex flex-col gap-2 p-4", className)} {...props} />
);
DrawerFooter.displayName = "DrawerFooter";

const DrawerTitle = React.forwardRef<React.ElementRef<typeof DrawerPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>>(
  ({ className, ...props }, ref) => (
    <DrawerPrimitive.Title ref={ref} className={cn("font-semibold tracking-tight text-lg leading-none", className)} {...props} />
  ),
);
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description ref={ref} className={cn("text-slate-500 text-sm dark:text-slate-400", className)} {...props} />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export { Drawer, DrawerPortal, DrawerOverlay, DrawerTrigger, DrawerClose, DrawerContent, DrawerHeader, DrawerFooter, DrawerTitle, DrawerDescription };
