import { mainNav } from "@/config/site";
import { I18N_NAMESPACES } from "@/constants/i18n";
import { ROUTES } from "@/constants/routes";
import { HotelIdentifiers } from "@/types";
import { cn } from "@/utils/cn";
import { getHotelLocalizedLink } from "@/utils/localeUtils";
import { LogInIcon, MessageSquare } from "lucide-react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { isServer } from "@/constants/common";
import OreaLogoWithText from "../Icon/icons/orea-logo-with-text";
import LanguageSwitch from "../LanguageSwitch";
import Link from "../Link";
import { Shell } from "../Shell";
import { Icons } from "../icons";

interface Props {
  user?: boolean;
  hotel?: HotelIdentifiers;
}

export const isActive = (href: string, pathname: string) => {
  if (href === ROUTES.HOMEPAGE) {
    return pathname === href;
  }

  if (href === ROUTES.RESERVATIONS) {
    return pathname === href || pathname.startsWith("/reservation/");
  }

  return pathname.startsWith(href);
};

const MobileNav = ({ user, hotel }: Props) => {
  const { t } = useTranslation(I18N_NAMESPACES.COMMON);
  const items = user ? mainNav : mainNav.filter((item) => !item.isPrivate);
  const router = useRouter();

  const referrer = !isServer ? document.referrer : "";
  const isFromOrea = referrer.includes("orea.cz");
  const externalLink = getHotelLocalizedLink(router.locale, hotel?.externalWebsiteUrl);
  const logoLink = router.pathname === ROUTES.SIGN_IN && isFromOrea ? referrer : externalLink;

  return (
    <Shell as="nav" className={cn("flex h-full w-full items-center justify-between md:hidden", { "justify-center px-2 md:px-4": user })}>
      {!user && (
        <Link href={logoLink} className="block w-fit focus-within:outline-none focus-within:ring-2 focus-within:ring-yellow-light-beige">
          <OreaLogoWithText className="h-8" />
        </Link>
      )}
      {user && (
        <ul className="flex h-full w-full items-center gap-1 font-semibold">
          {items.map((item) => {
            const Icon = Icons[item.icon];
            const isFaq = item.href === ROUTES.FAQ;
            let link = item.href;

            if (isFaq && hotel?.id !== undefined) {
              link = `${ROUTES.FAQ}/${hotel.id}`;
            }

            return (
              <Link
                key={item.labelKey}
                href={link}
                className={cn("flex h-full w-full flex-col items-center justify-center rounded-md text-xs", {
                  "bg-yellow-light-beige": isActive(link, router.asPath),
                })}
              >
                <Icon className="size-5" />
                {t(`nav.${item.labelKey}`)}
              </Link>
            );
          })}
        </ul>
      )}
      <div className="flex items-center gap-2">
        {!user && (
          <>
            <Link
              href={ROUTES.FAQ}
              className={cn("flex h-full w-full flex-col items-center justify-center rounded-md px-3 py-0.5 text-xs", {
                "bg-yellow-light-beige": isActive(ROUTES.FAQ, router.asPath),
              })}
            >
              <MessageSquare className="size-5" />
              {t("nav.faq")}
            </Link>
            <Link
              href={ROUTES.SIGN_IN}
              className={cn("flex h-full w-full flex-col items-center justify-center whitespace-nowrap rounded-md px-3 py-0.5 text-xs", {
                "bg-yellow-light-beige": isActive(ROUTES.SIGN_IN, router.asPath),
              })}
            >
              <LogInIcon className="size-5" />
              {t("nav.login")}
            </Link>

            <LanguageSwitch />
          </>
        )}
      </div>
    </Shell>
  );
};

export default MobileNav;
