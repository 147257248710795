import { create } from "zustand";

interface DeviceLayoutState {
  hideLanguageSwitcher: boolean;
  setHideLanguageSwitcher: (hide: boolean) => void;
}

const useDeviceLayoutStore = create<DeviceLayoutState>((set) => ({
  hideLanguageSwitcher: false,
  setHideLanguageSwitcher: (hide) => set({ hideLanguageSwitcher: hide }),
}));

export default useDeviceLayoutStore;
