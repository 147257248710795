import { createLucideIcon } from "lucide-react";

const OreaSettingsIcon = createLucideIcon("orea-settings", [
  [
    "path",
    {
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "m5.543 8.41-1.54-2.558 1.849-1.848L8.41 5.543v.004a6.909 6.909 0 0 1 1.563-.652L10.695 2h2.61l.722 2.895a6.907 6.907 0 0 1 1.563.652v-.004l2.558-1.54 1.844 1.849-1.535 2.558-.004-.004a7.03 7.03 0 0 1 .652 1.567l2.895.722v2.61l-2.895.722a6.907 6.907 0 0 1-.652 1.563h.004l1.535 2.558-1.844 1.844-2.558-1.535v-.004a6.907 6.907 0 0 1-1.563.652L13.305 22h-2.61l-.722-2.895a6.909 6.909 0 0 1-1.563-.652v.004l-2.558 1.535-1.848-1.844 1.539-2.558h.004a6.907 6.907 0 0 1-.652-1.563L2 13.305v-2.61l2.895-.722a7.014 7.014 0 0 1 .652-1.567l-.004.004Z",
      vectorEffect: "non-scaling-stroke",
    },
  ],
  [
    "path",
    {
      fill: "none",
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      d: "M16.348 12A4.346 4.346 0 0 1 12 16.348 4.346 4.346 0 0 1 7.652 12 4.346 4.346 0 0 1 12 7.652 4.346 4.346 0 0 1 16.348 12Z",
      vectorEffect: "non-scaling-stroke",
    },
  ],
]);

export default OreaSettingsIcon;
