import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";
import { HotelIdentifiers } from "@/types";
import { cn } from "@/utils/cn";
import { NavbarMode } from "./LayoutBase";
import MainNav from "./MainNav";
import MobileNav from "./MobileNav";
import SimpleNav from "./SimpleNav";

interface Props {
  user?: boolean;
  hotel?: HotelIdentifiers;
  navbarMode: NavbarMode;
}

const SiteHeader = ({ user, hotel, navbarMode }: Props) => {
  const { ref, isIntersecting } = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
    initialIsIntersecting: true,
  });

  return (
    <>
      <div ref={ref} />
      <div
        id="header"
        className={cn("sticky top-0 isolate z-50 flex h-[58px] items-center justify-between gap-12 bg-white py-2 transition-shadow", {
          "shadow-header": !isIntersecting,
          "shadow-sm": isIntersecting,
        })}
      >
        {navbarMode === "full" ? (
          <>
            <MainNav user={user} hotel={hotel} />
            <MobileNav user={user} hotel={hotel} />
          </>
        ) : (
          <SimpleNav />
        )}
      </div>
    </>
  );
};

export default SiteHeader;
