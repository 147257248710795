import { cn } from "@/utils/cn";
import { ReactNode } from "react";

export interface IconProps {
  className?: string;
  children?: ReactNode;
}

const Icon = ({ className, children }: IconProps) => <div className={cn("icon-container", className)}>{children}</div>;

export default Icon;
