import { cn } from "@/utils/cn";
import { isSupportedCountry } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

interface Props {
  countryCode: string;
  className?: string;
}

const CountryFlag = ({ countryCode, className }: Props) => {
  const validCountryCode = isSupportedCountry(countryCode) ? countryCode : null;
  const SelectedCountryFlag = validCountryCode && flags[validCountryCode];

  return (
    <span
      className={cn(
        "flex h-4 w-6 overflow-hidden rounded-sm bg-black-100 text-center",
        {
          "items-center justify-center": !SelectedCountryFlag,
        },
        className,
      )}
    >
      {SelectedCountryFlag ? <SelectedCountryFlag title={validCountryCode} /> : countryCode ?? "?"}
    </span>
  );
};

export default CountryFlag;
