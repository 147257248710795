import * as Types from '../../gqlTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: number; output: number; }
  CountryCode: { input: string; output: string; }
  DateTime: { input: Date; output: Date; }
  DateValue: { input: string; output: string; }
  EmailAddress: { input: string; output: string; }
  JSON: { input: any; output: any; }
  Latitude: { input: number; output: number; }
  Longitude: { input: number; output: number; }
  NegativeFloat: { input: number; output: number; }
  NegativeInt: { input: number; output: number; }
  NonEmptyString: { input: string; output: string; }
  NonNegativeFloat: { input: number; output: number; }
  NonNegativeInt: { input: number; output: number; }
  NonPositiveFloat: { input: number; output: number; }
  NonPositiveInt: { input: number; output: number; }
  PositiveFloat: { input: number; output: number; }
  PositiveInt: { input: number; output: number; }
  URL: { input: string; output: string; }
  UUID: { input: string; output: string; }
  Void: { input: void; output: void; }
  _Any: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export enum AccessCodeTypeEnum {
  Guest = 'Guest',
  Owner = 'Owner',
  Room = 'Room'
}

export type AccountPayment = {
  accounts: Array<Scalars['Int']['input']>;
  paymentType: Scalars['String']['input'];
  reservationId: Scalars['Int']['input'];
  tip?: InputMaybe<Scalars['PositiveFloat']['input']>;
};

export type AddressInput = {
  businessId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  businessName?: InputMaybe<Scalars['NonEmptyString']['input']>;
  city: Scalars['NonEmptyString']['input'];
  country: Scalars['NonEmptyString']['input'];
  street: Scalars['NonEmptyString']['input'];
  vatId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  zip: Scalars['NonEmptyString']['input'];
};

export enum CmsSectionType {
  Box = 'Box',
  Card = 'Card'
}

export enum GuestStateEnum {
  FilledOut = 'FilledOut',
  New = 'New',
  Shared = 'Shared'
}

export enum IdCardType {
  CarLicensePlate = 'CarLicensePlate',
  Id = 'Id',
  NoId = 'NoId',
  Passport = 'Passport',
  ResidencePermit = 'ResidencePermit'
}

export enum IdStayReason {
  Tourism = 'Tourism',
  Work = 'Work'
}

export type PaymentRedirects = {
  urlFailed: Scalars['String']['input'];
  urlPending: Scalars['String']['input'];
  urlSuccess: Scalars['String']['input'];
};

export enum PaymentResponseType {
  Post = 'POST',
  Redirect = 'REDIRECT'
}

export type ReceptionGuestInput = {
  address: ReservationRoomGuestAddressInput;
  born: Scalars['DateValue']['input'];
  carLicensePlate?: InputMaybe<Scalars['NonEmptyString']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstname: Scalars['NonEmptyString']['input'];
  gdprMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  gdprRepetitivestay?: InputMaybe<Scalars['Boolean']['input']>;
  gsm?: InputMaybe<Scalars['NonEmptyString']['input']>;
  id: Scalars['ID']['input'];
  idCard?: InputMaybe<Scalars['NonEmptyString']['input']>;
  idCardType?: InputMaybe<IdCardType>;
  nationality: Scalars['NonEmptyString']['input'];
  surname: Scalars['NonEmptyString']['input'];
  visa?: InputMaybe<Scalars['NonEmptyString']['input']>;
};

export enum ReservationDetailStateEnum {
  Active = 'Active',
  Incoming = 'Incoming',
  Utilized = 'Utilized'
}

export type ReservationRoomGuestAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['NonEmptyString']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type ReservationRoomGuestInput = {
  address: ReservationRoomGuestAddressInput;
  born: Scalars['DateValue']['input'];
  carLicensePlate?: InputMaybe<Scalars['NonEmptyString']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstname: Scalars['NonEmptyString']['input'];
  gdprMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  gdprRepetitivestay?: InputMaybe<Scalars['Boolean']['input']>;
  gsm?: InputMaybe<Scalars['NonEmptyString']['input']>;
  id: Scalars['ID']['input'];
  idCard?: InputMaybe<Scalars['NonEmptyString']['input']>;
  idCardType?: InputMaybe<IdCardType>;
  idStayReason?: InputMaybe<IdStayReason>;
  nationality: Scalars['NonEmptyString']['input'];
  surname: Scalars['NonEmptyString']['input'];
  visa?: InputMaybe<Scalars['NonEmptyString']['input']>;
};

export enum RoomState {
  CheckedIn = 'CheckedIn',
  CheckedOut = 'CheckedOut',
  Confirmed = 'Confirmed',
  Provisional = 'Provisional'
}

export enum SocialLoginType {
  Apple = 'Apple',
  Facebook = 'Facebook',
  Google = 'Google'
}

export enum TransactionState {
  Authorized = 'Authorized',
  Cancelled = 'Cancelled',
  Error = 'Error',
  InProcess = 'InProcess'
}

export type UserInput = {
  address: AddressInput;
  billingAddress?: InputMaybe<AddressInput>;
  born: Scalars['DateValue']['input'];
  email: Scalars['EmailAddress']['input'];
  firstname: Scalars['NonEmptyString']['input'];
  gsm?: InputMaybe<Scalars['NonEmptyString']['input']>;
  idCard?: InputMaybe<Scalars['NonEmptyString']['input']>;
  idCardType?: InputMaybe<IdCardType>;
  nationality: Scalars['NonEmptyString']['input'];
  surname: Scalars['NonEmptyString']['input'];
};

export type GetLanguagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLanguagesQuery = { __typename: 'Query', languages: Array<{ __typename: 'Language', id: string, localizedName?: string | null, feUsable: boolean, icon?: string | null }> };


export const GetLanguagesDocument = gql`
    query getLanguages {
  languages {
    id
    localizedName
    feUsable
    icon
  }
}
    `;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
      }
export function useGetLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export function useGetLanguagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesSuspenseQueryHookResult = ReturnType<typeof useGetLanguagesSuspenseQuery>;
export type GetLanguagesQueryResult = Apollo.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;