import { useEffect, useState } from "react";

const tailwindBreakpoints = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
} as const;

type TailwindBreakpoints = keyof typeof tailwindBreakpoints;

export function useMediaQuery(size: TailwindBreakpoints) {
  const query = `(min-width: ${tailwindBreakpoints[size]})`;

  const [value, setValue] = useState(false);

  useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches);
    }

    const result = matchMedia(query);
    result.addEventListener("change", onChange);
    setValue(result.matches);

    return () => result.removeEventListener("change", onChange);
  }, [query]);

  return value;
}
