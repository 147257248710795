import { Icons } from "@/components/icons";
import { ROUTES } from "@/constants/routes";

export interface NavItem {
  labelKey: string;
  href: string;
  icon?: keyof typeof Icons;
  isPrivate?: boolean;
}

export const mainNav = [
  {
    labelKey: "home",
    href: ROUTES.HOMEPAGE,
    icon: "logo",
  },
  {
    labelKey: "reservations",
    href: ROUTES.RESERVATIONS,
    icon: "calendar",
    isPrivate: true,
  },
  {
    labelKey: "faq",
    href: ROUTES.FAQ,
    isPrivate: true,
    icon: "chat",
  },
  {
    labelKey: "profile",
    href: ROUTES.ACCOUNT,
    icon: "profile",
    isPrivate: true,
  },
] satisfies NavItem[];
