import { COOKIE_LOCALE_NAME, DEFAULT_LOCALE } from "@/constants/i18n";
import { EXTERNAL_URLS } from "@/constants/routes";
import { GetServerSidePropsContext } from "next";
import { NextRequest, NextResponse } from "next/server";

export const getLanguageFromRequest = (req?: GetServerSidePropsContext["req"]): string => {
  const defaultLanguage = DEFAULT_LOCALE;
  if (!req) return defaultLanguage;

  const acceptLanguage = req.headers["accept-language"];
  if (!acceptLanguage) return defaultLanguage;

  const languageCode = acceptLanguage.split(",")[0]?.trim().split("-")[0];

  return languageCode || defaultLanguage;
};

export const handleLocaleInMiddleware = async (req: NextRequest) => {
  const locale = req.cookies.get(COOKIE_LOCALE_NAME)?.value || DEFAULT_LOCALE;
  const { pathname, search, locale: requestLocale } = req.nextUrl;

  if (pathname.startsWith("/api")) {
    return null;
  }

  if (requestLocale !== locale) {
    req.nextUrl.locale = locale;
    return NextResponse.redirect(new URL(`/${locale}${pathname}${search}`, req.url));
  }
  return null;
};

export const getExternalWebBookingLink = (locale: string = DEFAULT_LOCALE): string => {
  const baseLink = EXTERNAL_URLS.OREA_WEB;
  const bookingPath = EXTERNAL_URLS.OREA_BOOKING_PATH;

  switch (locale) {
    case "cs":
      return `${baseLink}/${bookingPath}`;
    case "en":
      return `${baseLink}/en${bookingPath}`;
    case "de":
      return `${baseLink}/de${bookingPath}`;
    default:
      return `${baseLink}/en${bookingPath}`;
  }
};

export const getHotelLocalizedLink = (locale: string = DEFAULT_LOCALE, externalWebsiteUrl?: string | null) => {
  if (externalWebsiteUrl) {
    if (locale !== "cs") {
      return externalWebsiteUrl.replace(/(https:\/\/www\.orea\.cz)(\/.*)/, `$1/${locale}$2`);
    }
    return externalWebsiteUrl;
  }
  return locale === "cs" ? EXTERNAL_URLS.OREA_WEB : `${EXTERNAL_URLS.OREA_WEB}/${locale}`;
};
